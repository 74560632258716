/* latin */
@font-face {
  font-family: '__Gloria_Hallelujah_42ddf2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/41e7ce1ad66c1045-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Gloria_Hallelujah_Fallback_42ddf2';src: local("Arial");ascent-override: 122.39%;descent-override: 50.26%;line-gap-override: 0.00%;size-adjust: 114.82%
}.__className_42ddf2 {font-family: '__Gloria_Hallelujah_42ddf2', '__Gloria_Hallelujah_Fallback_42ddf2';font-weight: 400;font-style: normal
}

